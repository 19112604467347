<template>
	<div>
		<el-dialog title="发送短信/邮件通知" :visible.sync="showDialog" width="90%" :close-on-click-modal="false" custom-class="width_800 cus_dialog"
			@close="closeDialog">
			<el-row style="margin-left: 30px; margin-block: 10px">
				<el-input placeholder="请输入关键字" v-model="nformData.keyword" class="input-with-select"
					style="width: 280px;" size="small">
					<el-select v-model="nformData.ktype" slot="prepend" placeholder="请选择" style="width: 100px;">

						<el-option label="学生姓名" value="stu_name"></el-option>
						<el-option label="学号" value="stu_code"></el-option>
						<el-option label="手机号" value="phone"></el-option>
						<el-option label="企业名称" value="sx_qiye"></el-option>
					</el-select>
				</el-input>
				<el-select v-model="nformData.gyear" @change="changeYear" placeholder="请选择" size="small"
					style="width: 120px; margin-left: 10px;">
					<el-option label="全部" value=""></el-option>
					<el-option v-for="(y,i) in YearList" :label="y.gyear" :value="y.gyear"></el-option>

				</el-select>
				<el-select v-model="nformData.class_name" placeholder="请选择班级" size="small"
					style="width: 120px; margin-left: 10px;">
					<el-option label="全部" value=""></el-option>
					<el-option v-for="(y,i) in ClassList" :label="y.class_name" :value="y.class_name"></el-option>
				</el-select>
				<el-select v-model="nformData.joined"  placeholder="请选择" size="small"
					style="width: 120px; margin-left: 10px;">
					<el-option label="全部" value=""></el-option>
					<el-option label="未参与" value="0"></el-option>
					<el-option label="已参与" value="1"></el-option>
				
				</el-select>
				<el-button size="small" icon="el-icon-search" style="margin-left: 10px;" @click="noticeSearch" plain
					type="primary">查询</el-button>
				<el-button size="small" icon="el-icon-refresh" @click="noticeReset" type="warning">重置</el-button>
				
				<el-button size="small" icon="el-icon-bell" @click="reNotify(0)" type="warning">给所有学生发短信和邮件</el-button>
				<el-button size="small" icon="el-icon-bell" @click="reNotify(1)" type="warning">给未参与学生发短信和邮件</el-button>
			</el-row>
			<el-table stripe :data="noticedataList" style="width: 100%; margin-bottom: 20px; color: #000" border
				size="mini">
				<el-table-column type="index" label="序号" width="80"></el-table-column>
				<el-table-column prop="stu_code" label="学号" min-width="80"></el-table-column>
				<el-table-column prop="stu_name" label="姓名" min-width="80"></el-table-column>
				<el-table-column prop="sx_qiye" label="实习企业" min-width="150"></el-table-column>
				<el-table-column prop="gyear" label="毕业年份" min-width="80"></el-table-column>
				<el-table-column prop="class_name" label="班级" min-width="80"></el-table-column>
				<el-table-column prop="phone" label="手机" min-width="100">
					<template slot-scope="scope">
						<a :href="'tel:'+scope.row.phone">{{(scope.row.phone||scope.row.sch_phone)}}</a>
						
					</template>
				</el-table-column>
				<el-table-column prop="phone_state" label="短信状态" min-width="100">
					<template slot-scope="scope">
						<span v-if="!scope.row.phone_state" style="color: blue; cursor: pointer;" title="点击发送" @click="sendSmsOne(scope.row)">未发送</span>
						<span v-if="scope.row.phone_state==1" style="color: green;">发送成功</span>
						<span v-if="scope.row.phone_state==2" style="color: orangered; font-size: 12px;" :title="scope.row.phone_result" >失败</span>
					</template>
				</el-table-column>
				<el-table-column prop="email" label="邮箱" min-width="150"></el-table-column>
				<el-table-column prop="email" label="邮箱状态" min-width="100">
					<template slot-scope="scope">
						<span v-if="scope.row.email_state==0" style="color: blue;">未发送</span>
						<span v-if="scope.row.email_state==1" style="color: green;">发送成功</span>
						<span v-if="scope.row.email_state==2" style="color: orangered; font-size: 12px;" :title="scope.row.email_result" >失败</span>
					</template>
				</el-table-column>
				
				<el-table-column prop="email" label="是否参与" min-width="100">
					<template slot-scope="scope">
						<span v-if="scope.row.joined" style="color: green;" @click="viewInfo(scope.row)">已参与</span>
						<span v-else style="color: orangered;">未参与</span>
						
					</template>
				</el-table-column>
				
				<el-table-column prop="email" label="是否领红包" min-width="100">
					<template slot-scope="scope">
						<span v-if="scope.row.prize_name" style="color: green;">{{scope.row.prize_name}}</span>
						<span v-else style="color: orangered;">未领</span>
						
					</template>
				</el-table-column>


			</el-table>

		</el-dialog>
		
		
		<el-dialog title="作答详情" :visible.sync="showWenjuan" width="800px" custom-class="width_800 cus_dialog" top="5%">
			<h5result :id="joinedID" v-if="showWenjuan" ></h5result>
		</el-dialog>

	</div>
</template>

<script>
	import h5result from "./h5result.vue"
	export default {
		props: ["plan"],
		components: {h5result},
		data() {
			return {
				showDialog: true,
				showDialog_notice: false,
				ClassList: [],
				nformData: {
					gyear: "",
					class_name: "",
					ktype: "stu_name",
					keyword: "",
					plan_id: 0,
					joined:""
				},
				noticedataList: [],
				YearList: [],
				showWenjuan:false,
				joinedID:0
			}
		},
		mounted() {
			this.nformData.plan_id = this.plan.id
			this.getYears()
		},
		methods: {
			getYears() {
				this.$http.post("/api/get_plan_gyear", {
					plan_id: this.plan.id
				}).then(res => {
					this.YearList = res.data

				})
			},
			showNotify(scope) {
				var self = this;
				self.showDialog_notice = true;
			},
			changeYear() {
				this.getClass()

			},
			getClass() {
				this.$http.post("/api/njwx_stu_classes", {
					year: this.nformData.gyear
				}).then(res => {
					this.ClassList = res.data
				})
			},
			noticeSearch() {
				console.log(this.nformData)
				this.$http
					.post("api/get_send_list", this.nformData)
					.then((res) => {

						this.noticedataList = res.data;

					});
			},
			noticeReset() {
				this.nformData = {};
				// this.getlist();
			},
			closeDialog() {
				this.$parent.showDialog_notice = false
			},
			reNotify(t){
				this.nformData.t=t
				this.$http
					.post("api/start_send_notify", this.nformData)
					.then((res) => {
				
						this.$message.success("后台正在发送中，请勿多次点击发送按钮！！！")
				
					});
			},
			viewInfo(row){
				this.joinedID = row.joined
				this.showWenjuan = true
			},
			sendSmsOne(row){
				this.$http
					.post("api/send_one_notify", {plan_id:this.nformData.plan_id,stu_id:row.stu_id})
					.then((res) => {
				
						this.$message.success("发送成功")
				
					});
			}
		},
	}
</script>