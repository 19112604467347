<template>
	<div style="height: 100%;">
		<!-- 增加匿名判断20210405 -->
		<div style="color: orangered; text-align: center; font-size: 12px; padding: 10px;"
			v-if="DataList.length>0&&DataList[0].noname==1"> 此问卷为匿名做答</div>
		<div style="color: orangered; text-align: center; font-size: 12px; padding: 10px;" v-if="expired">
			问卷已过期，截止时间：{{DataList[0].enddate}}</div>

		<div v-for="(wj,idx) in DataList" :key="idx" class="wj" :style="{backgroundImage:'url('+wj.ivst_bgimg+')'}">

			<div style="height: 15px;"></div>
			<img v-if="wj.ivst_headimg" :src="wj.ivst_headimg" style="width: 100%; vertical-align: middle;" />
			<div style="font-size: 16px; font-weight: bold;">{{wj.ivst_title}}</div>

			<div style=" padding: 10px 0;" v-html="wj.ivst_desc"></div>

			<div v-for="(tm,tmidx) in wj.tm_list" style="margin-bottom: 20px;">
				<div style="margin-bottom: 10px;">

					<span class="required" v-if="tm.required>0">*</span>
					<span class="notrequired" v-else>*</span>
					{{tmidx+1}}、{{tm.tm_title}}
				</div>
				<div v-if="tm.tm_type==('radio_normal')">
					<div v-for="(xx,xxidx) in tm.tm_content" :class="['xxitem',xx.choosed?'choosed':'']"
						>
						<i v-if="xx.choosed" class="iconfont">&#xe6a2;</i>
						<i v-else class="iconfont">&#xe619;</i>
						{{xx.title}}
					</div>
				</div>
				<div v-if="tm.tm_type==('radio_image')">
					<div style="display: inline-block; width: 35%; vertical-align: top;"
						v-for="(xx,xxidx) in tm.tm_content" :class="['xxitem',xx.choosed?'choosed':'']"
						>
						<i v-if="xx.choosed" class="iconfont">&#xe6a2;</i>
						<i v-else class="iconfont">&#xe619;</i>
						<div style="display: inline-block; width: 60%; padding-left: 10%; ">
							<img :src="xx.value" style="width: 100%;" />
						</div>
						<div style="padding-top:5px ;">
							{{xx.title}}
						</div>
					</div>
				</div>

				<div v-if="tm.tm_type==('vote_normal')">
					<div v-for="(xx,xxidx) in tm.tm_content" :class="['xxitem',xx.choosed?'choosed':'']"
						>
						<i v-if="xx.choosed" class="iconfont">&#xe6a2;</i>
						<i v-else class="iconfont">&#xe619;</i>
						{{xx.title}}
					</div>
				</div>
				<div v-if="tm.tm_type==('chart_normal')">
					<div v-for="(xx,xxidx) in tm.tm_content" :class="['xxitem',xx.choosed?'choosed':'']"
						>
						<i v-if="xx.choosed" class="iconfont">&#xe6a2;</i>
						<i v-else class="iconfont">&#xe619;</i>
						{{xx.title}}
					</div>
				</div>
				<div v-if="tm.tm_type==('vote_image')">
					<div style="display: inline-block; width: 35%; vertical-align: top;"
						v-for="(xx,xxidx) in tm.tm_content" :class="['xxitem',xx.choosed?'choosed':'']"
						>
						<i v-if="xx.choosed" class="iconfont">&#xe6a2;</i>
						<i v-else class="iconfont">&#xe619;</i>
						<div style="display: inline-block; width: 60%; padding-left: 10%; ">
							<img :src="xx.value" style="width: 100%;" />
						</div>
						<div style="padding-top:5px ;">
							{{xx.title}}
						</div>
					</div>
				</div>
				<div v-if="tm.tm_type==('chart_image')">
					<div style="display: inline-block; width: 35%; vertical-align: top;"
						v-for="(xx,xxidx) in tm.tm_content" :class="['xxitem',xx.choosed?'choosed':'']"
						>
						<i v-if="xx.choosed" class="iconfont">&#xe6a2;</i>
						<i v-else class="iconfont">&#xe619;</i>
						<div style="display: inline-block; width: 60%; padding-left: 10%; ">
							<img :src="xx.value" style="width: 100%;" />
						</div>
						<div style="padding-top:5px ;">
							{{xx.title}}
						</div>
					</div>
				</div>

				<div v-if="tm.tm_type==('checkbox_normal')">
					<div v-for="(xx,xxidx) in tm.tm_content" :class="['xxitem',xx.choosed?'choosed':'']"
						>
						<i v-if="xx.choosed" class="iconfont">&#xe61b;</i>
						<i v-else class="iconfont">&#xe622;</i>
						{{xx.title}}
					</div>
				</div>
				<div v-if="tm.tm_type==('checkbox_image')">
					<div style="display: inline-block; width: 35%; vertical-align: top;"
						v-for="(xx,xxidx) in tm.tm_content" :class="['xxitem',xx.choosed?'choosed':'']"
						>
						<i v-if="xx.choosed" class="iconfont">&#xe61b;</i>
						<i v-else class="iconfont">&#xe622;</i>
						<div style="display: inline-block; width: 60%; padding-left: 10%; ">
							<img :src="xx.value" style="width: 100%;" />
						</div>
						<div style="padding-top:5px ;">
							{{xx.title}}
						</div>
					</div>
				</div>


			</div>

			

			<div style="height: 15px;"></div>



		</div>




	</div>
</template>

<script>
	export default {
		props: ["id"],
		data() {
			return {
				DataList: [],
				joined: false,
				viewResult: false,
				expired: false //是否过期
			}
		},
		mounted() {

			this.getList();
		},
		methods: {
			getList(page) {
				this.$http.post("/api/ivst_result", {
					id: this.id
				}).then(
					res => {

						let myans = res.data.result
						let info = res.data
						let datiArr=[];
						try {
							myans.tm = JSON.parse(myans.tm)
							for (let tm of myans.tm) {
								tm.tmid==0?tm.tmid=326:false;
								tm.answers = JSON.parse(tm.answers)
							}
						} catch (e) {
							//TODO handle the exception
						}

						for (let tm of info.tm_list) {
							tm.choosedxx = false
							try {
								tm.tm_content = JSON.parse(tm.tm_content)

							} catch (e) {
								tm.tm_content = []
							}

						}



						for (let tm of info.tm_list) {
							try {
								for (let anstm of myans.tm) {
									if (anstm.tmid == tm.id) {
										for (let xx of tm.tm_content) {
											xx.choosed = false
											if (anstm.answers[xx.xxid]) {
												tm.choosedxx = true
												xx.choosed = true
											}
										}
										// if(anstm.tm_type.indexOf("text")>0){
											
										// }
									}
								}
							} catch (e) {
								tm.tm_content = []
							}
							
							if(tm.choosedxx||tm.id==326){
								datiArr.push(tm)
							}
						}
						info.tm_list = datiArr
						this.DataList = [info];

					})
			},
			chooseXX(tm, xx) {
				if (this.joined) {
					return
				}
				for (let txx of tm.tm_content) {
					txx.choosed = false;
				}
				xx.choosed = true;

			},
			chooseXXMore(tm, xx) { //多选
				if (this.joined) {
					return
				}
				xx.choosed = !xx.choosed;

			},
			showToast(msg) {
				$("#toast").text(msg).show().animate({
					opacity: 1
				}, 200, "linear", function() {
					setTimeout(() => {
						$("#toast").animate({
							opacity: 0
						}, "linear", 3000, function() {
							$("#toast").hide()
						})
					}, 2000)
				})
			},
			showAlert(msg) {
				$("#alertcontent").html(msg);
				$("#alert").css("display", "flex").animate({
					opacity: 1
				}, 200, "linear", function() {

				})
			},
			hideAlert() {
				$("#alert").animate({
					opacity: 0
				}, "linear", 3000, function() {
					$("#alert").hide()
				})
				if (this.viewResult) {
					//this.$router.push("/h5result/"+this.$route.params.id)
				}
			},
			showTJ() {
				this.$router.push("/h5result/" + this.$route.params.id)
			},
			submitData() {


				$("#alert2").animate({
					opacity: 0
				}, "linear", 3000, function() {
					$("#alert2").hide()
				})


				let total_value = 0;
				let total_score = 0;
				let resultMsg = "";
				for (let wj of this.DataList) {
					let i = 1;
					for (let tm of wj.tm_list) {
						if (tm.required > 0) {
							let choosed = false;
							for (let xx of tm.tm_content) {
								if (xx.choosed) {
									choosed = true;
									break;
								}
							}
							if (!choosed) {
								this.showToast("请选择第" + i + "题目")
								return
							}
						}
						//检查多选
						if (tm.tm_type.indexOf("checkbox") >= 0) {

							if (tm.min_cnt > 1 && tm.max_cnt > 1) { //最少选择个数
								let ckd_cnt = 0;
								for (let xx of tm.tm_content) {
									if (xx.choosed) {
										ckd_cnt++

									}
								}
								if (ckd_cnt < tm.min_cnt) { //选择正常
									this.showToast("第" + i + "题最少选择" + tm.min_cnt + "项")
									return
								}
								if (ckd_cnt > tm.max_cnt) { //选择正常
									this.showToast("第" + i + "题最多选择" + tm.max_cnt + "项")
									return
								}
							}
						}
						i++;
						//获取总分数
						for (let xx of tm.tm_content) {
							// if(xx.choosed&&xx.value&&!isNaN(xx.value)){
							//   total_value+= parseFloat( xx.value )
							// }
							if (xx.choosed && xx.score && !isNaN(xx.score)) {
								total_score += parseFloat(xx.score)
							}
						}
					}
					//计算结果
					let resultData = JSON.parse(wj.result_config);
					if (resultData.logic && resultData.logic.length > 0) {
						for (let logic of resultData.logic) {
							if (logic.min_ct > 0 && logic.max_ct > 0) { //大于等于，小于等于
								if (total_score >= logic.min && total_score <= logic.max) {
									resultMsg = logic.result
								}
							} else if (logic.min_ct > 0 && logic.max_ct == 0) { //大于等于，小于等于
								if (total_score >= logic.min && total_score < logic.max) {
									resultMsg = logic.result
								}
							} else if (logic.min_ct == 0 && logic.max_ct > 0) { //大于等于，小于等于
								if (total_score > logic.min && total_score <= logic.max) {
									resultMsg = logic.result
								}
							} else if (logic.min_ct == 0 && logic.max_ct == 0) { //大于等于，小于等于
								if (total_score > logic.min && total_score < logic.max) {
									resultMsg = logic.result
								}
							}
						}
					}

					this.$http.post("/admin/ivst_join", {
						ivst_id: wj.id,
						tm: JSON.stringify(wj.tm_list),
						score: total_score,
						result: resultMsg
					}).then(res => {
						if (wj.can_viewresult > 0) {
							this.$router.push("/h5result/" + this.$route.params.id)
						} else {
							this.showAlert("您的数据已提交，感谢您的参与！")
							setTimeout(() => {
								window.history.back()
							}, 2000)
						}
						this.joined = true

					})

				}

			},
			confirmTJ() {

				$("#alert2").css("display", "flex").animate({
					opacity: 1
				}, 200, "linear", function() {

				})
			},
		}
	}
</script>

<style scoped>
	.wj {
		padding: 0 15px;
		word-break: break-all;
		overflow: auto;
		background-size: cover;
		height: 100%;
	}

	.required {
		color: orangered;
	}

	.notrequired {
		color: #FFFFFF;
	}

	.xxitem {
		margin-left: 25px;
		padding: 10px;
		border: 1px solid #f6f6f6;
		margin-bottom: 5px;
		border-radius: 4px;
	}

	.choosed {
		color: #007AFF;
		border: 1px solid #007AFF;

	}

	.iconfont {
		display: inline-block;
		transform: translateY(2px);
		vertical-align: top;
	}

	.btntj {
		height: 40px;
		text-align: center;
		line-height: 40px;
		background: #007AFF;
		color: #FFFFFF;
		border-radius: 4px;
	}

	.toast {
		position: fixed;
		width: 70%;
		left: 15%;
		text-align: center;
		padding: 5px 0;
		border-radius: 4px;
		bottom: 50%;
		background: orangered;
		color: #FFFFFF;
		font-size: 14px;
		opacity: 0;
		display: none;
	}

	.alert {
		position: fixed;
		display: none;
		width: 100%;
		height: 100%;
		top: 0;
		z-index: 99;
		background: rgba(0, 0, 0, 0.6);
		opacity: 0;
	}

	.alertbox {
		width: 80%;
		background: #FFFFFF;
		border-radius: 4px;
		padding: 10px;
	}

	.alerttitle {
		font-size: 16px;
		text-align: center;
		border-bottom: 1px solid #f6f6f6;
		padding-bottom: 10px;
	}

	.alertcontent {
		padding: 20px 0;
		word-break: break-all;
		text-align: center;
	}

	.alertbutton {
		border-top: 1px solid #f6f6f6;
		padding-top: 10px;
		text-align: center;
	}
</style>
